const potentialBooks = [
  {
    id: 1,
    title: "Philosophy of Mathematics",
    author: "Hilary Putnam",
    date: "2022",
    rating: "10",
    topic: "Mathematics"
  },
  {
    id: 2,
    title: "The Bible",
    author: "-",
    date: "2022",
    rating: "10",
    topic: "Religion"
  },
  {
    id: 3,
    title: "Language Truth and Logic",
    author: "AJ Ayer",
    date: "2022",
    rating: "7",
    topic: "Philosophy"
  },
  {
    id: 4,
    title: "A History of the English-Speaking Peoples Vol 4",
    author: "Winston Churchill",
    date: "2022",
    rating: "10",
    topic: "History"
  },
  {
    id: 7,
    title: "History of The World War",
    author: "Francis A. March",
    date: "2022",
    rating: "8",
    topic: "History",
  },
  {
    id: 8,
    title: "Confessions",
    author: "St. Augustine",
    date: "2022",
    rating: "9",
    topic: "Religion",
  },
  {
    id: 9,
    title: "The Everlasting Man",
    author: "GK Chesterton",
    date: "2022",
    rating: "9",
    topic: "History",
  },
  {
    id: 10,
    title: "Mere Christianity",
    author: "CS Lewis",
    date: "2022",
    rating: "9",
    topic: "Religion",
  },
  {
    id: 11,
    title: "The Four Loves",
    author: "CS Lewis",
    date: "2022",
    rating: "9",
    topic: "Religion",
  },
  {
    id: 12,
    title: "Surprised By Joy",
    author: "CS Lewis",
    date: "2022",
    rating: "9",
    topic: "Religion",
  },
  {
    id: 13,
    title: "The Abolition of Man",
    author: "CS Lewis",
    date: "2022",
    rating: "9",
    topic: "Religion",
  },
  {
    id: 15,
    title: "The Divine Comedy",
    author: "Dante",
    date: "2022",
    rating: "9",
    topic: "Classics",
  },
  {
    id: 16,
    title: "The Structure of Scientific Revolutions",
    author: "Thomas Kuhn",
    date: "2023",
    rating: "8",
    topic: "Philosophy",
  },
  {
    id: 17,
    title: "The Road to Reality",
    author: "Roger Penrose",
    date: "2023",
    rating: "9",
    topic: "Science",
  },
  {
    id: 18,
    title: "Introduction to Electrodynamics",
    author: "David J Griffiths",
    date: "2023",
    rating: "9",
    topic: "Science",
  },
  {
    id: 19,
    title: "Differential Equations & Linear Algebra",
    author: "Stephen W. Goode",
    date: "2023",
    rating: "9",
    topic: "Mathematics",
  },
  {
    id: 17,
    title: "Macroeconomics",
    author: "Gregory Mankiw",
    date: "2023",
    rating: "9",
    topic: "Science",
  },
  {
    id: 18,
    title: "Seminar on Nietzsche's Zarathustra",
    author: "Carl Jung",
    date: "2023",
    rating: "9",
    topic: "Philosophy",
  },
  {
    id: 19,
    title: "Revolutionary Writings 1755-1775",
    author: "John Adams",
    date: "2023",
    rating: "9",
    topic: "History",
  },
  {
    id: 20,
    title: "The Federalist Papers",
    author: "Alexander Hamilton",
    date: "2023",
    rating: "9",
    topic: "History",
  },
  {
    id: 21,
    title: "The Pragmatic Programmer",
    author: "Andrew Hunt",
    date: "2023",
    rating: "9",
    topic: "Computer Science",
  },
  {
    id: 22,
    title: "The Aeneid",
    author: "Virgil",
    date: "2024",
    rating: "10",
    topic: "Classics",
  },
  {
    id: 23,
    title: "Paradise Lost",
    author: "John Milton",
    date: "2024",
    rating: "10",
    topic: "Classics",
  },
  {
    id: 24,
    title: "Faust",
    author: "Johann Wolfgang von Goethe",
    date: "2024",
    rating: "10",
    topic: "Classics",
  }
];

export default potentialBooks;
